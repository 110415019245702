<script setup>
import { ref, computed, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import {
  ComboboxAnchor, ComboboxContent, ComboboxEmpty, ComboboxInput,
  ComboboxItem, ComboboxRoot, ComboboxTrigger, ComboboxViewport
} from 'radix-vue'
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/vue/24/solid"
import { useFocusWithin } from '@vueuse/core'
import { useAudioPlayerStore } from '~/store'

const runtimeConfig = useRuntimeConfig();

const audioPlayerStore = useAudioPlayerStore()
const searchQuery = ref('')
const isLoading = ref(false)
const valueSelected = ref(null)
const searchResults = ref([])

// Function to sort and merge all results into a single list
const processSearchResults = (results) => {
  let mergedResults = []
  for (const category in results) {
    if (Array.isArray(results[category])) {
      mergedResults = mergedResults.concat(results[category])
    }
  }
  
  // Sort by score and then by popularity as a tiebreaker
  mergedResults.sort((a, b) => {
    // Convert BigInt scores to numbers for comparison
    const scoreA = Number(a.score)
    const scoreB = Number(b.score)
    
    if (scoreA !== scoreB) {
      return scoreB - scoreA // Primary sort by score
    }
    
    // If scores are equal, use popularity as tiebreaker
    return (b.popularity || 0) - (a.popularity || 0)
  })
  
  return mergedResults
}

const performSearch = async () => {
  if (!searchQuery.value) {
    searchResults.value = []
    return
  }

  isLoading.value = true
  console.log("[aphex] Performing search for:", searchQuery.value)

  try {
    const url = `${runtimeConfig.public.backend}/user/explore/search?query=${encodeURIComponent(searchQuery.value)}`
    console.log("[aphex] Fetching from URL:", url)
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    // Process and set the search results
    if (data && data.results && typeof data.results === 'object') {
      searchResults.value = processSearchResults(data.results)
    } else {
      searchResults.value = []
    }
  } catch (error) {
    console.error("[aphex] Search error:", error)
    searchResults.value = []
  } finally {
    isLoading.value = false
  }
}

const debouncedSearch = useDebounceFn(performSearch, 300)

const hasResults = computed(() => {
  const result = searchResults.value.length > 0
  console.log("[aphex] hasResults:", result, "searchResults:", searchResults.value)
  return result
})

const formatResult = (item) => {
  let result
  switch (item.type) {
    case 'set':
      result = `${item.name} (${item.metadata.artists?.join(', ') || 'Unknown Artist'})`
      break
    default:
      result = item.name
  }
  // Append the type label
  result += ` - ${item.type}`
  return result
}

const determineLink = (item) => {
  switch (item.type) {
    case 'set':
      return `/stream/${item.id}`
    case 'artist':
      return `/search/artist/${item.slug}`
    case 'festival':
      return `/search/festival/${item.slug}`
    case 'promoter':
      return `/search/promoter/${item.slug}`
    case 'genre':
      return `/search/genre/${item.slug}`
    default:
      return '#'
  }
}

watch(searchQuery, (newValue) => {
  console.log("[aphex] searchQuery changed to:", newValue)
  if (newValue) {
    debouncedSearch()
  } else {
    searchResults.value = []
  }
})

let inputFocus = ref(null)
const { focused } = useFocusWithin(inputFocus)

watch(focused, (focused) => {
  if (focused) {
    console.log("[aphex] target is focusing on search input, blocking keyboard shortcuts")
    audioPlayerStore.ignoreKeyboardShortcuts = true
  } else {
    console.log("[aphex] target is NOT focusing on search input, unblocking keyboard shortcuts")
    if (audioPlayerStore.ignoreKeyboardShortcuts) {
      audioPlayerStore.ignoreKeyboardShortcuts = false
    }
  }
})
</script>

<template>
  <ComboboxRoot
    v-model:search-term="searchQuery"
    v-model:selected-value="valueSelected"
    class="w-full max-w-full sm:max-w-3xl md:max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto"
  >
    <ComboboxAnchor class="w-full flex items-center bg-transparent text-white rounded-md shadow-sm group">
      <MagnifyingGlassIcon
        class="h-6 w-6 text-gray-400 ml-3 mr-4 group-hover:text-white transition-colors duration-300 ease-in-out hidden md:block"
      />
      <ComboboxInput
        ref="inputFocus"
        class="w-full pr-4 pl-4 py-2 bg-transparent outline-none text-white placeholder-white/50 border-b border-white/25 hover:border-white/50 focus:border-white transition-colors duration-300 ease-in-out"
        placeholder="Search..."
      />
      <ComboboxTrigger class="p-2 hidden md:block">
        <ChevronDownIcon class="h-5 w-5 text-gray-400" />
      </ComboboxTrigger>
    </ComboboxAnchor>
    <!-- Only display ComboboxContent if there are results or loading -->
    <ComboboxContent
      v-if="isLoading || hasResults"
      class="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 sm:max-h-72 md:max-h-80 lg:max-h-96 overflow-auto"
    >
      <ComboboxViewport class="py-1">
        <!-- Show loading indicator -->
        <div v-if="isLoading" class="px-4 py-2 text-sm text-gray-700">
          Loading...
        </div>
        <!-- Show "No results found" when not loading and no results -->
        <ComboboxEmpty v-else-if="!hasResults" class="px-4 py-2 text-sm text-gray-700">
          No results found
        </ComboboxEmpty>

        <!-- Display results in a single list -->
        <template v-else>
          <ComboboxItem
            v-for="item in searchResults"
            :key="item.id"
            class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
            :value="item"
          >
            <NuxtLink :to="determineLink(item)" class="flex items-center w-full">
              <span>{{ formatResult(item) }}</span>
            </NuxtLink>
          </ComboboxItem>
        </template>
      </ComboboxViewport>
    </ComboboxContent>
  </ComboboxRoot>
</template>